body {
	background-color: #f0f0f0;
	min-height: 100vh;

	/* background-image: url(https://i.imgur.com/CzmE8sy.png); */
	/* font-family: Inter, sans-serif; */
}

.blog {
	/* max-width: 800px; */
	font-size: 18px;
	/* font-family: sans-serif; */
}

.definition {
	position: absolute;
	background-color: #f0f0f0;
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 3px;
	display: none; /* Initially hidden */
}

.word:hover .definition {
	display: block; /* Show on hover */
}
