// change the theme
// $theme-colors: (
// 	"primary": #0d6efd,
// 	"danger": #ff4136,
// );
$primary: #0d6efd;
$card-bg: #f8f9faf2;

#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $card-bg;
	z-index: 9999;
}

#status {
	width: 40px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -20px 0 0 -20px;
}

.spinner-chase {
	margin: 0 auto;
	width: 40px;
	height: 40px;
	position: relative;
	animation: spinner-chase 2.5s infinite linear both;
}

.chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: chase-dot 2s infinite ease-in-out both;
	&:before {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		background-color: $primary;
		border-radius: 100%;
		animation: chase-dot-before 2s infinite ease-in-out both;
	}

	&:nth-child(1) {
		animation-delay: -1.1s;
		&:before {
			animation-delay: -1.1s;
		}
	}
	&:nth-child(2) {
		animation-delay: -1s;
		&:before {
			animation-delay: -1s;
		}
	}
	&:nth-child(3) {
		animation-delay: -0.9s;
		&:before {
			animation-delay: -0.9s;
		}
	}
	&:nth-child(4) {
		animation-delay: -0.8s;
		&:before {
			animation-delay: -0.8s;
		}
	}
	&:nth-child(5) {
		animation-delay: -0.7s;
		&:before {
			animation-delay: -0.7s;
		}
	}
	&:nth-child(6) {
		animation-delay: -0.6s;
		&:before {
			animation-delay: -0.6s;
		}
	}
}

@keyframes spinner-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}

.component-loader-wrapper {
	position: relative;
	width: 100%; /* Adjust width as needed */
	height: 100%; /* Adjust height as needed */
}

.component-loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: $card-bg;
	z-index: 9999;
}

.component-status {
	width: 40px; /* Adjust size as needed */
	height: 40px; /* Adjust size as needed */
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.component-spinner-chase {
	margin: 0 auto;
	width: 40px; /* Adjust size as needed */
	height: 40px; /* Adjust size as needed */
	position: relative;
	animation: spinner-chase 2.5s infinite linear both;
}

.component-chase-dot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: chase-dot 2s infinite ease-in-out both;

	&:before {
		content: "";
		display: block;
		width: 25%;
		height: 25%;
		background-color: $primary;
		border-radius: 100%;
		animation: chase-dot-before 2s infinite ease-in-out both;
	}

	// Animation delay adjustments go here based on the number of dots
}

@keyframes spinner-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot {
	80%,
	100% {
		transform: rotate(360deg);
	}
}

@keyframes chase-dot-before {
	50% {
		transform: scale(0.4);
	}
	100%,
	0% {
		transform: scale(1);
	}
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
